window.addEventListener('DOMContentLoaded', function() {
    const selectedLanguage = document.querySelector('.selected-language');
    const languageList = document.querySelector('.language-list');

    let currentLanguage = languageList.querySelector('.current-lang');
    let currentLanguageFlag = currentLanguage.innerHTML;

    selectedLanguage.querySelector('.language-text').innerHTML = currentLanguageFlag;
    
    selectedLanguage.addEventListener('click', function() {
      languageList.classList.toggle('show');
    });
    
    const languageItems = languageList.querySelectorAll('li');
    languageItems.forEach(function(languageItem) {
      languageItem.addEventListener('click', function() {
        const selectedValue = languageItem.getAttribute('data-value');
        const selectedText = languageItem.querySelector('a').innerHTML;
        selectedLanguage.querySelector('.language-text').innerHTML = selectedText;
        languageList.classList.remove('show');
        // Faire autre chose avec la valeur sélectionnée...
      });
    });
    
    document.addEventListener('click', function(event) {
      if (!selectedLanguage.contains(event.target)) {
        languageList.classList.remove('show');
      }
    });
    
    
    
});