(function ($) {
    "use strict";

    $(document).ready(function () {

        //Sticky header
        if ($('.site-header').length > 0) {
            var header = $('.site-header').offset().top;
            $(window).on('scroll', function () {
                var scrollPos = $(this).scrollTop();
                if (scrollPos > header) {
                    $('.site-header').addClass('is-sticky');
                    $('body').addClass('has-sticky');
                } else {
                    $('.site-header').removeClass('is-sticky');
                    $('body').removeClass('has-sticky');
                }
            });
        }

    });

})(jQuery);