// Splide slider for ".who-slider-avocat"
// ------------------------------------------------------------------------
window.addEventListener('DOMContentLoaded', function () {
    const splide = new Splide('.splide.slider-avocat', {
        type: 'slide',
        perPage: 5,
        perMove: 1,
        rewind: true,
        breakpoints: {
            1200: {
                perPage: 4,
            },
            992: {
                perPage: 2,
            },
            576: {
                perPage: 1,
            },
        },
    });

    splide.mount();
});

// ------------------------------------------------------------------------
