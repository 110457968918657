window.addEventListener('DOMContentLoaded', function() {
    console.log('footer.js chargé');
        var menu = document.querySelector('footer #primary-menu');
    console.log(menu);
        // Obtention des éléments avec et sans enfants
        var items = Array.from(menu.children);
        console.log(items); 
        var itemsWithoutChildren = items.filter(item => !item.classList.contains('menu-item-has-children'));
        var itemsWithChildren = items.filter(item => item.classList.contains('menu-item-has-children'));
    
        // Création des colonnes
        for (var i = 0; i < 5; i++) {
            var column = document.createElement('div');
            column.classList.add('column');
            column.id = 'column-' + (i + 1);  
            menu.appendChild(column);  
        }
    
        // Ajout des éléments sans enfants dans la première colonne
        var column1 = document.querySelector('#column-1');
        itemsWithoutChildren.forEach(item => column1.appendChild(item));
    
        // Répartition des éléments avec enfants dans les autres colonnes
        var columnIndex = 2;
        itemsWithChildren.forEach(item => {
            var column = document.querySelector('#column-' + columnIndex);
            column.appendChild(item);
            columnIndex = columnIndex < 5 ? columnIndex + 1 : 2;
        });
    });
