import './components/lang.js';
import './components/footer.js';
import './components/slider.js';
import './components/sticky-header.js';

(function ($) {
    "use strict";

    $(document).ready(function () {

        //console.log( 'scripts.js who' ) ;
        //   — — — — — — — — — — — — — — — — — — — — — — — — — — — —

    });

})(jQuery);